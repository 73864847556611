.address {
    position: relative;
}

.address > .map {
    position: fixed;

    top: -250px;

    width: 300px;
    height: 200px;

    background-color: white;
    z-index: 1;

    border: 1px solid #cccccc;
    border-radius: 4px;
}

.address:hover > .map {
    position: absolute;
    top: 20px;
}